import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // Define your application state here
  },
  mutations: {
    // Define your mutations here
  },
  actions: {
    // Define your actions here
  },
  getters: {
    // Define your getters here
  },
})
