<template>
  <div class="home">
    <GatedMinter />
  </div>
</template>

<script>
// @ is an alias to /src
import GatedMinter from '@/components/GatedMinter.vue'

export default {
  name: 'Home',
  components: {
    GatedMinter
  }
}
</script>
