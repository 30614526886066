module.exports = {
  TreeMetadata: {
    ipfsHash: 'QmU9ayChDeAwLQwLoHFNhfPMj2EZzW1CrZNUsQohmrfwPr'
  },
  ContractAddresses: {
    merkleMinter: '0x86153A0d9c060BE48A0cfc2daEed3Eee957C9DD7'
  },
  NetworkConfig: {
    chainId: 1
  }
}
