<template>
  <section class="hero has-text-centered">
    <div class="hero-body">
      <figure class="image is-128x128 has-text-centered is-inline-block mb-6">
        <img class="is-rounded" :src="imgUrl">
      </figure>
      <div v-if="!isConnected">
        <button @click="connect" class="button is-primary is-large">Connect</button>
      </div>
      <div v-else>
        <div class="columns is-centered mb-6">
          <div class="column is-two-thirds">
            <div class="notification is-info">
              <p class="title">{{userClaims.length}} available to mint</p>
              <p class="subtitle">Connected to {{accountName ? accountName : account}}</p>
            </div>
          </div>
        </div>
        <p>
          <button v-if="userClaims.length > 0" @click="mint" class="button is-primary is-large">Mint one</button>
        </p>
        <p>
          <button v-if="userClaims.length > 1" @click="mintBatch" class="button is-primary is-large">Mint all {{userClaims.length}}</button>
        </p>
        <div v-if="txUrl" class="margin-top: 3px;">
          <a target="_blank" style="color: white;" :href="txUrl">
            Etherscan Transaction
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// todo - if
// todo - regarding open sea url if it was implemented, how to handle batch mints

import MerkleMinterABI from '@/abis/MerkleMinter.json';

import Onboard from 'bnc-onboard';
import Notify from 'bnc-notify';
import {ethers} from "ethers";
import axios from "axios";

import { TreeMetadata, ContractAddresses, NetworkConfig } from '@/config';

// todo move to env
const BLOCKNATIVE_KEY = '7a0a4da1-7c92-46af-a12e-e810c1b39d3e'
const NETWORK = NetworkConfig.chainId

const notify = Notify({
  dappId: BLOCKNATIVE_KEY,       // [String] The API key created by step one above
  networkId: NETWORK,  // [Integer] The Ethereum network ID your Dapp uses.
  darkMode: true
});

let provider
let onboard
let network
let account
let balance

export default {
  name: 'GatedMinter',
  props: {
    msg: String
  },
  data() {
    return {
      isConnected: false,
      account: '',
      accountName: null,
      imgUrl: require('../assets/holding.png'),
      contract: null,
      claims: [],
      userClaims: [],
      tx: null,
      txUrl: null,
      openseaUrl: null,
      baseOpenseaUrl: '',
      baseEtherscanUrl: 'https://rinkeby.etherscan.io/'
    }
  },
  methods: {
    async mint() {
      this.tx = await this.contract.gatedMint(
          this.account,
          this.getDnasForUser()[0],
          this.getProofsForUser()[0]
      )

      notify.hash(this.tx.hash)

      this.txUrl = `${this.baseEtherscanUrl}/tx/${this.tx.hash}`

      await this.tx.wait()

      await this.initContracts()
    },
    async mintBatch() {
      this.tx = await this.contract.multiGatedMint(
          this.account,
          this.getDnasForUser(),
          this.getProofsForUser()
      )

      this.txUrl = `${this.baseEtherscanUrl}/tx/${this.tx.hash}`

      await this.tx.wait()

      await this.initContracts()
    },
    getDnasForUser() {
      return this.userClaims.map(
          ({ nfcId, birthday, tokenUri }) => ({ nfcId, birthday, tokenUri })
      );
    },
    getProofsForUser() {
      return this.userClaims.map(claim => claim.proof)
    },
    async connect() {

      onboard = Onboard({
        dappId: BLOCKNATIVE_KEY,
        hideBranding: true,
        darkMode: true,
        networkId: NETWORK,  // [Integer] The Ethereum network ID your Dapp uses.
        subscriptions: {
          wallet: async (wallet) => {

            provider = new ethers.providers.Web3Provider(wallet.provider);
            network = await provider.getNetwork();

            // Setup contracts
            //init();

            this.isConnected = true
          },
          address: (_account) => {
            console.log('Wallet address found', _account);
            this.account = _account;
            //init();
          },
          balance: (_balance) => {
            console.log('Balance change found', _balance);
            balance = _balance;
          },
          network: (chainId) => {
            console.log('Connected to chainId', chainId);
            //network = _network
            //init()
          }
        },
        walletCheck: [
          {checkName: 'derivationPath'},
          {checkName: 'connect'},
          {checkName: 'accounts'},
          {checkName: 'network'},
          // {checkName: 'balance', minimumBalance: '100000'},
        ],
        walletSelect: {
          wallets: [
            {
              walletName: 'metamask',
              preferred: true
            },
            {
              walletName: 'walletConnect',
              infuraKey: 'f081a32b30bd439891818968bda7e4f6',
              preferred: true,
            },
          ],
        }
      })

      await onboard.walletSelect();

      const readyToTransact = await onboard.walletCheck();
      if (readyToTransact) {
        await this.initContracts()
      }
    },
    async initContracts() {
      provider.lookupAddress(this.account).then(name => this.accountName = name)

      // bootstrap merkle minter
      this.contract = new ethers.Contract(
          ContractAddresses.merkleMinter,
          MerkleMinterABI,
          provider.getSigner()
      )

      // fetch all gated mint user mints for the connected account
      this.userClaims = []
      let hasUpdatedImage = false
      if (this.account) {
        const filteredClaims = this.claims.filter(claim => claim.recipient.toLowerCase() === this.account.toLowerCase())

        for (let i = 0; i < filteredClaims.length; i++) {
          const claim = filteredClaims[i]

          await this.contract.canGatedMint(
              this.account,
              {
                nfcId: claim.nfcId,
                birthday: claim.birthday,
                tokenUri: claim.tokenUri
              },
              claim.proof
          ).then(canGatedMint => {
            if (canGatedMint) {
              if (!hasUpdatedImage) {
                axios.get(`https://ipfs.infura.io/ipfs/${claim.tokenUri}`).then(({ data: tokenMetadata }) => {
                  this.imgUrl = tokenMetadata.image
                  hasUpdatedImage = true
                })
              }

              this.userClaims.push(claim)
            }
          })
        }
      }
    }
  },
  async mounted() {
    const latestGatedMerkleTree = TreeMetadata.ipfsHash
    console.log('TreeMetadata.ipfsHash', TreeMetadata.ipfsHash)
    const {data: merkleTreeMetadata} = await axios.get(`https://ipfs.infura.io/ipfs/${latestGatedMerkleTree}`)

    if (merkleTreeMetadata.claims) {
      // convert object into array
      this.claims = Object.keys(merkleTreeMetadata.claims).map(key => merkleTreeMetadata.claims[key])

      // Before any wallet is connected, display a random image
      const claim = this.claims[0]
      const { data: tokenMetadata } = await axios.get(`https://ipfs.infura.io/ipfs/${claim.tokenUri}`)
      this.imgUrl = tokenMetadata.image
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/*h3 {*/
/*  margin: 40px 0 0;*/
/*}*/
/*ul {*/
/*  list-style-type: none;*/
/*  padding: 0;*/
/*}*/
/*li {*/
/*  display: inline-block;*/
/*  margin: 0 10px;*/
/*}*/
/*a {*/
/*  color: #42b983;*/
/*}*/
</style>